import * as React from "react"
import Title from "./Title"
import Maquinaria from "./Maquinaria"
import { useTranslation } from "react-i18next"



const ProductsFilter = ({ data }) => {
    const { t } = useTranslation()
    return (
        <div>
            <Title
                title={t("products", { returnObjects: true }).product.title} />
            <Maquinaria
                content={[
                    {
                        image: "home/maquinaria/rececao-selecao-c.jpg",
                        alt: 'image',
                        title: data.machine.title[0],
                        //link: '/categoria-produto/vindima',
                        blackText: false,
                        alignRight: true,
                        alignBottom: true,
                    },
                    {
                        image: "home/maquinaria/prensa-deposito-c.jpg",
                        alt: 'image',
                        title: data.machine.title[1],
                        //link: '/categoria-produto/armazenamento',
                        blackText: false,
                        alignRight: true,
                        alignBottom: true,
                    },
                    {

                        image: "home/maquinaria/enchimento-paletizacao-pb.jpg",
                        alt: 'image',
                        title: data.machine.title[2],
                        link: "/categoria-produto/enchimento",
                        blackText: false,
                        alignRight: true,
                        alignBottom: true,
                    },
                    {
                        image: "home/maquinaria/visao-artificial-c.jpg",
                        alt: 'image',
                        title: data.machine.title[3],
                        link: '/categoria-produto/visao-artificial',
                        blackText: false,
                        alignRight: true,
                        alignBottom: true,
                    }
                ]}
            />
        </div>
    )
}

export default ProductsFilter

