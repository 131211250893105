import * as React from "react"

import Slider from "react-slick"
import LeftArrow from '../../images/home/services/arrow-left-transp.svg'
import RightArrow from '../../images/home/services/arrow-right-transp.svg'
import styled from "styled-components"
import { Image } from "../../components/Images"

const SlideHomeMobile = ({ data }) => {

    function NextArrow(props) {
        const { className, onClick, onKeyUp } = props
        return (
            <div className={className} onClick={onClick} onKeyUp={onKeyUp} role='button' tabIndex='0'>
                <RightArrow />
            </div>
        )
    }

    function PrevArrow(props) {
        const { className, onClick, onKeyUp } = props
        return (
            <div className={className} onClick={onClick} onKeyUp={onKeyUp} role='button' tabIndex='0'>
                <LeftArrow />
            </div>
        )
    }

    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 1500,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        swipeToSlide: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        dots: true,
        appendDots: dots => (
            <div className='dots'>
                {dots}
            </div>
        ),
        customPaging: i => (
            <div className='dot'>
                {i + 1}
            </div>
        )
    }


    return (

        <SlideHomeMobileStyled>
            <div className='container'>
                <div className='slider'>
                    <Slider {...settings}>
                        {data.slidesMobile.map((i, key) => (
                            <div className='slide'>
                                <Image src={i.slide} key={key} alt='slide' />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </SlideHomeMobileStyled>

    )
}

export default SlideHomeMobile



const SlideHomeMobileStyled = styled.div`
    .container{
        .slider{
            position:relative;

            .slick-slider{
                overflow:hidden;

                .slick-track{
                    display:flex;

                    .slick-slide{
                        .image{
                            max-height:75vh;
                        }
                    }
                }

                .slick-arrow{
                    width:10px;
                    color:#0047ba;
                    position: absolute;
                    top: 50%;
                    z-index:8;
                    cursor:pointer;
                }

                .slick-next{
                    right:5%;
                }

                .slick-prev{
                    left:2%;
                }

                .slick-disabled{
                    display:none;
                }

                .slick-dots{
                    display: flex;
                    justify-content: center;
                    margin-top:clamp(20px, 3vw, 50px);

                    li{
                        margin:0 10px;

                        ::marker{
                            content:'';
                        }

                        .dot{
                            width: 10px;
                            height: 10px;
                            background-color:#F2AF00;
                            opacity:0.5;
                            border-radius: 50%;
                            font-size: 0;
                            cursor:pointer;  
                        }    
                    }

                    li.slick-active .dot{
                        opacity:1;
                        cursor:default;
                    }
                }
            }
        }
    }
`