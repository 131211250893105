import * as React from "react"
import styled from 'styled-components'
import { color } from "../Styles"
import { Image } from "../../components/Images"
import Button2 from "../Button"
import { useTranslation } from "react-i18next"


const EncomendasBanner = ({ data }) => {
    const { t } = useTranslation()
    return (
        <EncomendasStyled>
            <div className='wrapper'>
                <div className='container'>
                    <div className='content'>
                        <h2 dangerouslySetInnerHTML={{ __html: data.title }}></h2>
                        <span className='description' dangerouslySetInnerHTML={{ __html: data.subtitle }}></span>
                    </div>
                    <Button2
                        text={t("home", { returnObjects: true }).bottom.title[1]}
                        to="/manutencao"
                    />
                </div>
                <div className="encomendas">
                    <Image src={data.image} alt={data.alt} />
                </div>
            </div>
        </EncomendasStyled>
    )
}

export default EncomendasBanner

const EncomendasStyled = styled.div`
    position:relative;

    .wrapper{
        max-width:1920px;
        margin:0 auto;
        padding:clamp(30px,10vw,15%) 10%;
        padding-bottom: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .container{
            grid-area:container;
            display:flex;
            flex-direction:column;
            justify-content:flex-start;
            gap:2rem;
            max-width: 500px;

            .content{
                h2{
                    font-weight: 700;
                    font-size: 3rem;
                    text-transform:uppercase;
                    margin-bottom:1rem;
                    color: ${color.black};

                    
                }

                .description{
                    line-height:1.8rem;
                }   
            }
        }

        .encomendas {
            width: 40%;
        }
    }
`