import * as React from "react"
import styled from 'styled-components'
import { color, media } from './Styles'
import { Image } from './Images'
import { Link } from 'gatsby'
import Slide from 'react-reveal/Fade';

const Maquinaria = ({ content }) => {
    return (
        <MaquinariaStyled>
            <div className='background'></div>
            <div className='background2'></div>
            <div className='wrapper'>
                <Slide  left>
                    {content.map((content, key) => (
                        <Link
                            to={content.link}
                            className={`container ${content.alignRight ? 'right' : ''} ${content.alignBottom ? 'bottom' : ''} ${content.blackText ? 'black' : 'white'}`}
                            key={key}
                        >
                            <Image src={content.image} alt={content.alt} />
                            <div className='content'>
                                {content.title &&
                                    <h1 dangerouslySetInnerHTML={{ __html: content.title }}></h1>
                                }
                                {content.text &&
                                    <span className='text' dangerouslySetInnerHTML={{ __html: content.text }}></span>
                                }
                            </div>
                        </Link>
                    ))}
                </Slide >
            </div>
        </MaquinariaStyled>
    )
}

export default Maquinaria

const MaquinariaStyled = styled.div`
    position:relative;
    overflow:hidden;
    
    .background{
        width: 100px;
        height: 100px;
        background-color: #fff;
        position: absolute;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        margin: -50px 0 0 -50px;
        z-index: 1;
    }
    .background2{
        width: 50px;
        height: 50px;
        background-color: ${color.yellow};
        position: absolute;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        margin: -25px 0 0 -25px;
        z-index: 1;
    }

    .wrapper{
        max-width:1920px;
        margin:0 auto;
        padding: clamp(0px,0vw,0px) 10%;
        display:grid;
        grid-template-columns:repeat(2,1fr);
        gap:3rem;

        ${media.m`
            grid-template-columns:1fr;
        `}

        .container{
            position:relative;  
            height: 35vh;
            max-height: 400px; 
            padding: 5%;
            box-sizing: border-box;     
               

            .image{
                position:absolute;
                width:100%;
                height:100%;
                top:0;
                left:0;
                z-index:-1; 
                border:1px solid #d5d5d5; 

                :before{
                    content:'';
                    position:absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    background-color:#000;
                    opacity: 15%;
                    z-index: 1;
                    
                }
            } 

            .content{
                max-width:75%;
                margin-left: auto;
                margin-right: auto;

                ${media.xl`
                    max-width:85%;
                `}

                ${media.m`
                    max-width:90%;
                `}

                ${media.s`
                    max-width:100%;
                `}

                h1{
                    font-weight: 800;
                    text-transform:uppercase;
                    letter-spacing:0.1rem;        
                    font-size: 15px;
                    padding: 0px 40px;
                    background-color: ${color.yellow};

                    ${media.xl`
                        font-size:10px;
                    `}
                }

                .text{
                    font-weight:500;
                    font-size:1.5rem;

                    ${media.xl`
                        font-size:1.2rem;
                    `}
                }
            }   
        }   
        
        .right{
            text-align: center;
            display: flex;
            justify-content: start;
            filter: grayscale(1);
            transition: 1s ease-out;

            :hover {
                
                filter: none;
            }
        }

        .bottom{
            display: flex;
            align-items: center;
        }

        .black{
            color: ${color.black}
        }

        .white{
            color: ${color.white}
        }
    }
`