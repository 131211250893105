import React, { useState, useEffect } from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Services from "../components/Services"
import ProductsFilter from "../components/ProductsFilter"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import SlideHome from "../components/SliderHome/SliderHome"
import { ColorRing } from 'react-loader-spinner'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import { graphql } from "gatsby"
import Marcas from "../components/Marcas"
import SlideHomeMobile from "../components/SliderHome/SlideHomeMobile"
import TextSide from "../components/textSide/TextSide"
import EncomendasBanner from "../components/Encomendas/EncomendasBanner"
import EncomendasBannerMobile from "../components/Encomendas/EncomendasBannerMobile"


const IndexPage = () => {
    const { t } = useTranslation()
    const breakpoints = useBreakpoint()


    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)

        }, 300)
    }, [])

    //SPINNER STYLE
    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

    return (
        <>
            {loading ?
                <div style={style}>
                    <ColorRing
                        height="150"
                        width="150"
                        radius="9"
                        colors={['#CC9200', '#F2AF00', '#FFBF1F', '#FFCB47', '#F2AF00']}
                        ariaLabel="blocks-loading"
                        wrapperClass="blocks-wrapper"
                        visible={true}
                    />
                </div>
                :
                <Layout>
                    <Seo titleTemplate="%s" title="ENOMAC" />

                    {breakpoints.m ?
                        (
                            <>
                                <SlideHomeMobile data={t("home", { returnObjects: true })} />
                                <TextSide
                                    image="home/home-about.png"
                                    alt="Enomac"
                                    title={t("home", { returnObjects: true }).banner.title}
                                    subtitle={t("home", { returnObjects: true }).banner.subtitle}
                                    content={t("home", { returnObjects: true }).banner.text}
                                    btn={t("home", { returnObjects: true }).bottom.title[0]}
                                    reverse
                                />
                                <div id='produto' />
                                <ProductsFilter data={t("home", { returnObjects: true })} />
                                <Services data={t("services", { returnObjects: true })} />
                                <EncomendasBannerMobile data={t("home", { returnObjects: true }).store} />
                                <Marcas data={t("home", { returnObjects: true })} />
                            </>

                        )
                        :
                        (
                            <>
                                <SlideHome data={t("home", { returnObjects: true })} />
                                <TextSide
                                    image="home/home-about.png"
                                    alt="Enomac"
                                    title={t("home", { returnObjects: true }).banner.title}
                                    subtitle={t("home", { returnObjects: true }).banner.subtitle}
                                    content={t("home", { returnObjects: true }).banner.text}
                                    btn={t("home", { returnObjects: true }).bottom.title[0]}
                                    reverse
                                />
                                <div id='produto' />

                                <ProductsFilter data={t("home", { returnObjects: true })} />
                                <Services data={t("services", { returnObjects: true })} />
                                <EncomendasBanner
                                    data={t("home", { returnObjects: true }).store}

                                />
                                <Marcas data={t("home", { returnObjects: true })} />
                            </>
                        )}
                </Layout>
            }
        </>
    )
}

export default connect(
    state => ({
        products: state.app.products,
        categories: state.app.categories
    }),
    null
)(IndexPage)

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home", "products", "services", "store"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`
