import React, { useState } from 'react';
import styled from 'styled-components'
import { color, media } from './Styles'
import Title from "./Title"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

import { Image } from "./Images"

const Services = ({ data }) => {
    const { t } = useTranslation()

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleHover = (index) => {
        setHoveredIndex(index);
    };

    return (
        <ServicesStyled>
            <div className="background">
                <p className="back-text">
                    we make it simple
                </p>
            </div>
            <div className='wrapper'>
                <Title
                    title={t("services", { returnObjects: true }).service.title}
                />

                <section className='services'>
                    {data.servicesCards.Icons.map((data, i) => (

                        <div className='service' key={i}
                            onMouseEnter={() => handleHover(i)}
                            onMouseLeave={() => handleHover(null)}>
                            <Link to={data.link}>
                                <div className="icon">
                                    {hoveredIndex === i ? (
                                        <Image src={data.svgHovered} className="image" alt={data.alt} />
                                    ) : (
                                        <Image src={data.svg} className="image" alt={data.alt} />
                                    )}
                                </div>
                            </Link>
                            <span className="title">{data.title}</span>
                        </div>
                    ))}

                </section>
            </div>
        </ServicesStyled>
    )
}

export default Services

const ServicesStyled = styled.div`
    position:relative;
    overflow:hidden;

    .background {
        width: 100%;
        height: 100%;
        
        .back-text {
            font-size: 9vw;
            margin: 0;
            color: #80808061;
            text-transform: uppercase;
            font-weight: bold;
            text-align: center;
            padding: 5% 0 5% 0;

            ${media.s`
                font-size: 15vw;
                line-height: 100%;
                padding: 0%;
                margin-top: 3rem;
            `}
        }
    }

    .wrapper{
        padding:5% 5%;
        color: #000;
        
        ${media.s`
            padding: 3rem 3rem;
        `}

        .services{
            display: flex;
            justify-content: space-between;
            gap: 5rem 3%;
            align-items: center;
            flex-wrap:wrap;
            position: relative;
            z-index: 1;
            background: ${color.grey};

            ${media.l`
                gap: 5rem 2%;
                margin: 0px;
            `}

            ${media.m`
                gap: 5rem 1%;
                margin: 0px;
            `}

            ${media.s`
                gap: 5rem 10%;
                margin: 0px;
            `}


            .service{
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: 150px;
                text-align: center;

                ${media.l`
                    max-width: 114px;
                `}

                .icon {
                    width:60px;
                    height:88px;

                    .image {
                        width: 100%;
                    }

                    ${media.l`
                        width:70px;
                    `}

                    ${media.s`
                        width:60px;
                    `}

                }
                
                .title{
                    font-weight:500;
                    text-transform: uppercase;
                    font-size: 1rem;
                    letter-spacing: 0.2rem;
                }
            }
        }
    }

`
